import { WagmiConfig, createConfig, mainnet } from 'wagmi';
import { ConnectKitButton, ConnectKitProvider, getDefaultConfig } from 'connectkit';
import UnityComponent from './Components/UnityComponent';
import React from 'react';
import styles from './App.module.css';
import { goerli } from '@wagmi/chains';
import Popup from './Components/Popup/Popup';
// import { Connectors } from './Components/Connectors';

const appName = "Game";

function App() {
  const config = createConfig(
    getDefaultConfig({
      // Required API Keys
      alchemyId: process.env.REACT_APP_ALCHEMY_ID ?? "",
      //infuraId: process.env.REACT_APP_INFURA_ID ?? "",
      walletConnectProjectId: process.env.REACT_APP_WALLETCONNECT_PROJECT_ID ?? "",

      // Required
      appName: appName,
      chains: [mainnet, goerli]
    }),
  );

  const clearSession = () => {
    sessionStorage.removeItem('qbrr-locl-act');
  };

  return (
    <div className={styles.container}>
      {/* <button onClick={clearSession}> Clear storage </button> */}

      <WagmiConfig config={config}>
        {/* <Connectors /> */}
        <ConnectKitProvider onDisconnect={clearSession}>
          <ConnectKitButton />
          <UnityComponent appName={appName} />
        </ConnectKitProvider>
      </WagmiConfig>
      <Popup /> 
    </div>
  );
}

export default App;
