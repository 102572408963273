import { useCallback, useEffect } from "react";

function ReactAPIHandler({ addEventListener, removeEventListener }) {
  //href
  const handleWindowHref = useCallback((url) => {
    console.log("href: " + url);
    setTimeout(() => {
      window.location.href = url;
    });
  }, []);

  //wondow open
  const handleWindowOpenUrl = useCallback((url, name) => {
    console.log("Window Open: " + url);
    setTimeout(() => {
      window.open(url, name);
    });
  }, []);

  useEffect(() => {
    addEventListener("WindowHref", handleWindowHref);
    addEventListener("WindowsOpenUrl", handleWindowOpenUrl);

    return () => {
      removeEventListener("WindowHref", handleWindowHref);
      removeEventListener("WindowsOpenUrl", handleWindowOpenUrl);
    };
  }, [
    addEventListener,
    removeEventListener,
    handleWindowHref,
    handleWindowOpenUrl,
  ]);

  return null;
};

export default ReactAPIHandler;
