/* eslint-disable jsx-a11y/alt-text */
import styles from './LoadingComponent.module.css';

function LoadingComponent({ isLoaded, loadingProgression }) {
    const totalImages = 20; // Общее количество изображений
    const imagesToShow = Math.ceil(loadingProgression * totalImages);

    return isLoaded === false && (
        <div className={styles.loaderContainer}>
            <div className={styles.loaderWrapper}>
                <h1 className={styles.text}>
                    Loading<span>.</span>
                    <span>.</span>
                    <span>.</span>
                </h1>
                <div className={styles.loadingBar}>
                    <div className={styles.loadingbarfillBg}>
                        {Array.from({ length: imagesToShow }).map((_, index) => (
                            <img
                                key={index}
                                src="/images/loading.png"
                                alt={`Loading ${index + 1}`}
                                className={styles.loadingImage}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LoadingComponent;